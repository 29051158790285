import { IconContext } from "react-icons";
import { BsFillMoonStarsFill } from 'react-icons/bs';


const IconDarkMode = ({ color, size, title }) => {
    return (<IconContext.Provider value={{ color: color, size: size, title: title }}>
        <div>
            <BsFillMoonStarsFill />
        </div>
    </IconContext.Provider>
    )
}
export default IconDarkMode